import React from 'react';

import './index.css';
import NotExist from '../../components/NotExist';

function Portifolio() {
  return (
    <div id="portfolio-page">
      <NotExist />
    </div>
  );
};

export default Portifolio;
