import React from 'react';

import Routes from './routes';

import './pages/global.css';

function App() {
  return (
    <Routes/>
  );
}

export default App;
