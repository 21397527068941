import React from 'react';

import './index.css';
import NotExist from '../../components/NotExist';

function About() {
  return (
    <div id="about-page">
      <NotExist />
    </div>
  );
};

export default About;
